import FormPage from '@/components/FormPage'
import security from '@/security'
import { DateTime } from 'luxon'
import {
  createGetParams,
  createPostParams,
} from '@/rest'
import {
  formatAddress,
  formatPhoneNumber,
  getNumeralFromRm,
  getRm,
} from '@/utils'

const formTitle = 'Upgrade DREG Plan to Black'

export default {
  extends: FormPage,
  data () {
    return {
      blank: {
        code: '',
        price: '',
        initData: '',
        initFreeOnNetCalls: '',
        initFreeOffNetCalls: '',
        initFreeOnNetSms: '',
        initFreeOffNetSms: '',
        tier: '',
        description: '',
      },
      breadcrumbs: [
        {
          text: 'Upgrade DREG', disabled: true,
        },
      ],
      source: null,
      title: formTitle,
      packageDetails: {
        fields: {
          erecharge: {
            name: 'E-recharge balance',
          },
          dregPackage: {
            component: () => import(/* webpackChunkName: "selectField" */ '@/components/SelectField'),
            name: 'DREG Package',
            props: {
              items: [],
              itemText: 'code',
              maxHeight: '270',
              label: 'Select Black package',
              required: true,
              returnObject: true,
              persistentHint: true,
              dense: true,
              rules: [
                val => !!val || 'Package is required!',
              ],
            },
          },
          unitPrice: {
            name: 'Required e-recharge',
            strong: true,
          },
        },
        subTitle: 'Purchase upgrade using e-recharge',
        title: 'Purchase Details',
      },
      profile: {
        fields: {
          title: {
            component: () => import(/* webpackChunkName: "titleField" */ '@/components/TitleField'),
            name: 'Title',
          },
          name: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Full name',
            props: {
              disabled: true,
              label: 'Full name',
              persistentHint: true,
              required: false,
            },
          },
          id: {
            component: () => import(/* webpackChunkName: "personalIdField" */ '@/components/PersonalIdField'),
            name: 'MyKad number',
            props: {
              disabled: true,
              label: 'Enter MyKad number',
              required: false,
            },
          },
          dob: {
            component: () => import(/* webpackChunkName: "dobField" */ '@/components/DobField'),
            name: 'Date of birth',
            props: {
              disabled: true,
              required: false,
            },
          },
          gender: {
            component: () => import(/* webpackChunkName: "genderField" */ '@/components/GenderField'),
            name: 'Gender',
          },
          race: {
            component: () => import(/* webpackChunkName: "raceField" */ '@/components/RaceField'),
            name: 'Race',
            props: {
              required: true,
            },
          },
          maidenName: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            hide: false,
            name: 'Mother maiden name',
            props: {
              hint: 'Name before you got married',
              label: 'Enter mother maiden name',
              persistentHint: true,
            },
          },
        },
        subTitle: 'Update profile',
        title: 'Profile',
      },
      contactDetails: {
        fields: {
          contactNumber: {
            component: () => import(/* webpackChunkName: "phoneField" */ '@/components/PhoneField'),
            name: 'Contact number',
            props: {
              hint: 'Contactable land or mobile number',
              label: 'Enter phone number',
              required: true,
              validateOnBlur: true,
            },
          },
          street1: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Street',
            props: {
              disabled: true,
              label: 'Street',
              required: false,
            },
          },
          street2: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: '',
            props: {
              disabled: true,
              required: false,
            },
          },
          city: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'City',
            props: {
              disabled: true,
              label: 'City',
              required: false,
            },
          },
          postalCode: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Postal code',
            props: {
              disabled: true,
              label: 'Postal code',
              mask: '#####',
              maxlength: 5,
              required: false,
            },
          },
          state: {
            component: () => import(/* webpackChunkName: "stateProvinceField" */ '@/components/StateProvinceField'),
            name: 'State',
            props: {
              disabled: true,
              required: false,
            },
          },
        },
        title: 'Contact details',
      },
      submission: {
        fields: {
          title: {
            name: 'Title',
          },
          name: {
            name: 'Name',
          },
          id: {
            name: 'MyKad number',
          },
          dob: {
            name: 'Date of birth',
          },
          gender: {
            name: 'Gender',
          },
          race: {
            name: 'Race',
          },
          maidenName: {
            name: 'Mother maiden name',
          },
          contactNumber: {
            name: 'Contact number',
          },
          address: {
            name: 'Address',
          },
          disclaimer: {
            component: () => import(/* webpackChunkName: "billingDisclaimer" */ '@/components/BillingDisclaimer'),
            name: 'Billing disclaimer',
          },
          email: {
            name: 'Email',
            strong: true,
          },
          dregPackage: {
            name: 'DREG Package',
            strong: true,
          },
          amount: {
            emphasize: true,
            name: 'Amount',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    address () {
      return formatAddress(this.contactDetails.fields)
    },
    erecharge: function () {
      return this.packageDetails.fields.erecharge
    },
    // Override
    formSteps: function () {
      return {
        packageDetails: this.packageDetails,
        profile: this.profile,
        contactDetails: this.contactDetails,
        submission: this.submission,
      }
    },
    package: function () {
      return this.packageDetails.fields.dregPackage.value
    },
  },
  mounted: function () {
    this.packageDetails.fields.unitPrice.value = getRm(0)
    this.submission.fields.email.value = security.me.email()

    this.$rest.all([this.getErechargeBalance(), this.getBlackDregPackages(), this.getDealerProfile()])
      .then(this.$rest.spread((erecharge, dregPackage, profile) => {
        this.erecharge.error = null
        this.erecharge.value = getRm(Number(erecharge.data.erecharge))

        for (const item of dregPackage.data) {
          const newItem = Object.assign({}, this.blank)

          Object.assign(newItem, item)
          this.packageDetails.fields.dregPackage.props.items.push(newItem)
        }

        this.profile.fields.name.value = profile.data.name
        this.profile.fields.id.value = profile.data.id
        this.contactDetails.fields.street1.value = profile.data.street
        this.contactDetails.fields.city.value = profile.data.city
        this.contactDetails.fields.postalCode.value = profile.data.postalCode
        this.contactDetails.fields.state.value = profile.data.state
      }).bind(this)).catch(error => {
        this.erecharge.error = error.message
      })
  },
  watch: {
    'profile.fields.id.value': function (val) {
      if (!val || val.length < 12) return

      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const myKad = {
        dob: val.substr(0, 6),
        state: val.substr(6, 2),
        gender: val.substr(11, 1) % 2 ? 'Male' : 'Female',
      }
      const yy = Number(myKad.dob.substr(0, 2))
      const mm = myKad.dob.substr(2, 2)
      const dd = myKad.dob.substr(4, 2)
      let year = 1900 + yy

      this.submission.fields.id.value = myKad.dob + '-' + myKad.state + '-' + val.substr(8)
      if (now.year - year > 80) year = 2000 + yy
      myKad.dob = String(year) + '-' + mm + '-' + dd

      this.profile.fields.dob.validate && this.profile.fields.dob.validate()
      this.profile.fields.dob.value = myKad.dob

      this.profile.fields.gender.value = myKad.gender
    },
    'profile.fields.title.value': function (val) {
      this.submission.fields.title.value = val
    },
    'profile.fields.name.value': function (val) {
      this.submission.fields.name.value = val
    },
    'profile.fields.dob.value': function (val) {
      this.submission.fields.dob.value = val
    },
    'profile.fields.maidenName.value': function (val) {
      this.submission.fields.maidenName.value = val
    },
    'contactDetails.fields.contactNumber.value': function (val) {
      this.submission.fields.contactNumber.value = formatPhoneNumber(val)
    },
    'profile.fields.gender.value': function (val) {
      this.submission.fields.gender.value = val
      if (val === 'Male' || this.profile.fields.race.value === 'Malay') {
        this.profile.fields.maidenName.hide = true
        this.profile.fields.maidenName.value = null
      } else {
        this.profile.fields.maidenName.hide = false
      }
    },
    'profile.fields.race.value': function (val) {
      this.submission.fields.race.value = val
      if (val === 'Malay' || this.profile.fields.gender.value === 'Male') {
        this.profile.fields.maidenName.hide = true
        this.profile.fields.maidenName.value = null
      } else {
        this.profile.fields.maidenName.hide = false
      }
    },
    'contactDetails.fields.street1.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.street2.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.city.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.postalCode.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.state.value': function () {
      this.submission.fields.address.value = this.address
    },
    package: function (val) {
      if (!val) return

      this.packageDetails.fields.dregPackage.props.hint = val.description
      this.submission.fields.dregPackage.value = val.code
      const amount = Number(val.price)
      this.packageDetails.fields.unitPrice.value =
        this.submission.fields.amount.value = getRm(amount)

      if (amount > getNumeralFromRm(this.erecharge.value)) {
        this.packageDetails.fields.unitPrice.error = 'Insufficient e-recharge'
      } else {
        this.packageDetails.fields.unitPrice.error = null
      }
    },
  },
  methods: {
    getBlackDregPackages: function () {
      return this.$rest.get('getBlackPackages.php')
    },
    getErechargeBalance: function () {
      const params = createGetParams({
        erecharge: true,
      })
      return this.$rest.get('upgrade/getEcash.php', params)
    },
    getDealerProfile: function () {
      const params = createGetParams({

      })
      return this.$rest.get('upgrade/getProfile.php', params)
    },
    submit: function () {
      const payload = {
        package: this.packageDetails.fields.dregPackage.value.code,
        email: this.submission.fields.email.value,
        title: this.profile.fields.title.value,
        name: this.profile.fields.name.value,
        id: this.profile.fields.id.value,
        dob: this.profile.fields.dob.value,
        gender: this.profile.fields.gender.value,
        race: this.profile.fields.race.value,
        maiden: this.profile.fields.maidenName.value,
        contact: this.contactDetails.fields.contactNumber.value,
        street1: this.contactDetails.fields.street1.value,
        street2: this.contactDetails.fields.street2.value,
        city: this.contactDetails.fields.city.value,
        postalCode: this.contactDetails.fields.postalCode.value,
        state: this.contactDetails.fields.state.value,
      }

      const params = createPostParams({
        action: 'upgradeDreg',
        payload: payload,
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.$rest.post('postAction.php', params)
        .then(function (response) {
          this.alertText = 'Your request to upgrade to Black dealer was successfully submitted.'
          this.status = this.submission.status = 'success'
          if (this.isImpersonated()) {
            this.showSubmissionResponse(response.data)
          } else {
            setTimeout(() => {
              this.$router.push('/')
            }, 3500)
          }
          this.submission.submit = false
        }.bind(this)).catch(e => {
          if (this.$rest.isCancel(e)) {
            this.alertText = 'Your request to upgrade to Black dealer was successfully submitted.'
            this.status = this.submission.status = 'success'
            setTimeout(() => {
              this.$router.push('/')
            }, 3500)
            this.submission.submit = false
          } else if (e.response.status === 403) {
            this.$router.push('/')
          } else if (e.response.status === 400 && e.response.data.startsWith('Invalid code')) {
            this.$router.push('/')
          } else {
            this.status = this.submission.status = 'error'
            this.submission.errorMessage = e.message + ': ' + e.response.data
            this.submission.submit = false
          }
        })

      setTimeout(() => {
        this.source.cancel('Forced timeout.')
        this.source = null
      }, 9000)
    },
  },
}
